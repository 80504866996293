<template>
  <div class="scoreInquiry-wrap">
    <Nav :pt="isIndex" />
    <div class="contents">
      <div class="title-wrap">
        <div>{{ titleObj.title1 }}</div>
        <div class="mt-5 title">{{ titleObj.title2 }}</div>
      </div>
      <div class="name">姓名：{{ form.name }}</div>
      <!-- 社会水平考级 -->
      <div class="info" v-if="form.type == 'testScoreInquiry'">
        <table class="table" border="1">
          <tr>
            <td>报考科目</td>
            <td>报考级别</td>
            <td>报考曲目</td>
            <td>等第</td>
          </tr>
          <tr v-for="(item, index) in scoreList" :key="index">
            <td>{{ item.examSubject }}</td>
            <td>{{ item.examLevel }}</td>
            <td>{{ item.songCategory }}</td>
            <td>{{ item.score }}</td>
          </tr>
        </table>
      </div>
      <!-- 青少年大赛 -->
      <div class="info" v-else>
        <table class="table" border="1">
          <tr>
            <td>演奏曲目</td>
            <td>最终得分</td>
            <td>奖项</td>
          </tr>
          <tr v-for="(item, index) in scoreList" :key="index">
            <td>{{ item.songName }}</td>
            <td>{{ item.score }}</td>
            <td>{{ item.awards }}</td>
          </tr>
        </table>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Nav from "@/components/nav.vue";
import Footer from "@/components/footer.vue";
export default {
  components: {
    Nav,
    Footer,
  },
  data() {
    return {
      form: {
        name: "",
        idNumber: "",
        type: "",
      },
      scoreList: [],
      titleObj: {
        title1: "",
        title2: "",
      }
    };
  },
  mounted() {
    this.form = this.$route.query;
    if (this.form.type == "teenagerScoreInquiry") {
      this.titleObj = {
        title1: "第二届苏州市青少年民族器乐展演",
        title2: "成绩查询",
      };
    } else if (this.form.type == "testScoreInquiry") {
      this.titleObj = {
        title1: "2022年苏州民族管弦乐团",
        title2: "社会艺术水平考级成绩查询",
      };
    }
    // 成绩查询
    this.scoreInquirySearch();
  },
  methods: {
    // 成绩查询
    scoreInquirySearch() {
      let ajaxUrl = "";
      if (this.form.type == "testScoreInquiry") {
        ajaxUrl = this.$ajax.localOrOnline() + "examRegistration/getScore";
      } else {
        ajaxUrl = this.$ajax.localOrOnline() + "instrument/competition/getInstrumentScore";
      }
      this.$ajax
        .get(ajaxUrl, this.form)
        .then((res) => {
          if (res.code == 200) {
            if (res.data && res.data.length) {
              this.scoreList = res.data;
            } else {
              this.$dialog
                .alert({
                  title: "提示",
                  message: "没有获取到您的考试信息",
                })
                .then(() => {});
            }
          } else {
            this.$toast.fail(res.message);
          }
        });
    },
  },
};
</script>
<style scoped lang="scss">
.scoreInquiry-wrap {
  .contents {
    font-family: Source Han Sans CN, Source Han Sans CN-Regular;
    background-image: url("../../assets/art/art-33.png");
    background-size: 100% 100%;
    margin: 65px 0 15px;
    padding: 50px 0 150px;
    min-height: 300px;
    .title-wrap {
      text-align: center;
      color: #444444;
      font-size: 18px;
      letter-spacing: 2px;
      font-weight: 400;
      .title {
        font-size: 18px;
        color: #845c46;
      }
    }
    .name {
      width: 90%;
      font-weight: 400;
      color: #444444;
      margin: 32px auto 8px;
      text-align: left;
      font-size: 14px;
    }
    .info {
      .table {
        width: 90%;
        margin: 0 auto;
        background: #fffaf6;
        border: 1px solid #979797;
        border-collapse: collapse;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        color: #444444;
        td {
          width: 30%;
          height: 45px;
        }
      }
    }
  }
}
</style>